/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { fetchCaseStudyWithSlug, useLocale } from '@modules';
import { CaseStudy, ChildrenProps } from '@types';

import {
  Paragraph, SectionWithContent, SubTitle,
} from '@core';
import PostDetails from 'src/components/composite/casestudies/PostDetails';
import { getStrapiRoutes } from 'src/modules/networking/routes';

interface MessageProps extends ChildrenProps{
  message: string
}

const styles = {
  messageSection: {
    variant: 'flex:center',
  },
};

const Message = ({ message, children }: MessageProps) => <SectionWithContent sx={styles.messageSection}>
  <SubTitle>{message}</SubTitle>
  {children}
</SectionWithContent>;

const useSlug = () => {
  const [slug, setSlug] = React.useState('');

  const getSlug = () => {
    setSlug(window.location.search.match(/slug=(?<value>[\w-]+)/)?.groups?.value || '');
  };

  React.useEffect(() => getSlug(), []);

  return slug;
};

export default () => {
  const [pageContext, setPageContext] = React.useState<CaseStudy | null>(null);
  const [error, setError] = React.useState<Error | null>(null);
  const locale = useLocale();
  const slug = useSlug();

  const getCaseStudyData = async () => {
    if (slug) {
      try {
        const casestudy = await fetchCaseStudyWithSlug(locale, slug);
        setPageContext(casestudy[locale] || casestudy.de);
      } catch (e) {
        setError(e);
      }
    }
  };

  React.useEffect(() => {
    (async () => getCaseStudyData())();
  }, [slug, locale]);

  return (
    <React.Fragment>
      {!pageContext && (<Message message={error ? `${error.message} with slug "${slug}" for language "${locale}".` : 'Loading...'}>
        {error && <Paragraph>{`You can try the following to resolve: <li> make sure you have used the correct slug:&nbsp;**${slug}** </li><li> switch the language above to &nbsp;**${locale === 'en' ? 'de' : 'en'}**  </li><li> make sure had you connected English and German article on the &nbsp;<a href="${getStrapiRoutes().cms.dashboard}">CMS Admin</a></li>`}</Paragraph>}
      </Message>)}
      {pageContext && <PostDetails pageContext={pageContext} />}
    </React.Fragment>

  );
};
